<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách dự toán</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label v-if="status == ''" for="status">[Tất cả trạng thái duyệt]</label>
                                <label v-else for="status">Trạng thái duyệt</label>
                                <md-select v-model="status" name="status" id="status" multiple class="search">
                                    <md-checkbox @change="selectAllStatus()" v-model="selectedAllStatus" style="padding-left: 16px;">Chọn tất cả</md-checkbox>
                                    <span style="border:1px solid #ccc;"></span>
                                    <md-option value="0">Lưu tạm</md-option>
                                    <md-option value="1">Chờ duyệt</md-option>
                                    <md-option value="2">Đã duyệt</md-option>
                                    <md-option value="4">Yêu cầu chỉnh sửa</md-option>
                                    <md-option value="3">Không duyệt</md-option>
                                    <md-option value="5">Đã quyết toán</md-option>
                                </md-select>
                            </md-field>
                        </div>    
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số dự toán</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:80px;">#</th>
                                <th style="width:10%;">Trạng thái</th>
                                <th class="left" style="width:10%;">Dự toán</th>
                                <th class="left" style="width:20%;">Mã dự toán</th>
                                <th class="left" style="width:10%;">Người dự toán</th>
                                <th style="width:10%;">Ngày dự toán</th>
                                <th class="right" style="width:10%;">Tổng dự toán</th>
                                <th style="width:10%;">Tạm ứng</th>
                                <th style="width:10%;">Quyết toán</th> 
                                <th style="width:10%;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="center">
                                    <md-chip v-if="item.status == 0" class="status-2 status-temp">Lưu tạm</md-chip>
                                    <md-chip v-if="item.status == 1" class="status-2 status-inprogress">Chờ duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 2" class="status-2 status-done">Đã duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 3" class="status-2 status-reject">Không duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 4" class="status-2 status-edit">Yêu cầu chỉnh sửa ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 5" class="status-2 status-invoice">Đã quyết toán</md-chip>
                                    <md-chip v-else-if="item.status == 6" class="status-2 status-comment">Ý kiến ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                </td>
                                <td class="left">{{item.type}}</td>
                                <td class="left">{{item.estimateCode}}</td>
                                <td class="left">{{item.staff.fullName}}</td>
                                <td class="center">{{item.estimateDate}}</td>
                                <td class="right">{{formatNumber(item.estimateAmount)}}</td>
                                <td class="center">
                                    <md-chip  v-if="item.isAdvancePayment" class="md-primary">Đã tạm ứng</md-chip>
                                    <md-chip v-else>Chưa tạm ứng</md-chip>
                                </td>
                                <td class="center">
                                    <md-chip  v-if="item.isInvoice" class="md-primary">Đã quyết toán</md-chip>
                                    <md-chip v-else>Chưa quyết toán</md-chip>
                                </td>
                                <td class="action">
                                    <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                                        <i class="uil uil-eye"></i>
                                        <md-tooltip>Xem</md-tooltip>
                                    </md-button>

                                    <md-button :disabled="item.status > 0 && item.status != 4" @click="edit(item.id)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    
                                    <md-button :disabled="item.status > 0" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import estimateService from '../../../api/estimateService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';

    export default {
        metaInfo: {
            title: 'Danh sách dự toán'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, code: '', status: '' },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                status: [],
                selectedAllStatus: false
            }
        },
        async created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            if(this.$route.query.status != undefined){
               this.search.status = this.$route.query.status;
               this.status = this.$route.query.status.split(',')
            }
            else{
                this.status = [];
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.filter();
            },

            selectAllStatus(){
                if(this.selectedAllStatus){
                    this.status = ['0','1','2','3', '4', '5'];
                }
                else{
                    this.status = [];
                }
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.status = '';
                this.status = [];
                this.search = { pageIndex: 1, pageSize: common.pageSize, status: '', code: '' };
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + "&code=";
                this.$router.push('/estimate?' + url).catch(()=>{});
                this.getData();
            },

            async filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + this.search.status + "&code=" + this.search.code;
                this.$router.push('/estimate?' + url).catch(()=>{});

                await this.getData();
            },

            async getData(){
                this.loadding = true;
                await estimateService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                estimateService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$router.push('/estimate/crud');
            },

            edit(id){
                this.$router.push('/estimate/crud/' + id);
            },
            
            editRequest(id){
                this.$router.push('/estimate/request-edit/' + id);
            },

            view(id){
                this.$router.push('/estimate/view/' + id);
            }
        },
        watch: {
            status: function () {
                this.search.status = String(this.status);
                if(this.status.length < 4){
                    this.selectedAllStatus = false;
                }
                else{
                    this.selectedAllStatus = true;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
